import CustomSourceFlowText from "@/components/Shared/CustomSourceflowText";
import ShuffleJobs from "src/components/Shared/ShuffleJobs";
import Link from "next/link";
import cn from "classnames";
import localizePath from "@/functions/localizePath";
import useLocale from "@/hooks/useLocale";

export default function FindRole({
  titleColor = "purple",
  hoverColor,
  title = "Finding your perfect role in tech starts now",
  path,
  jobs,
}) {
  const locale = useLocale();

  if (jobs.length === 0) {
    return null;
  }

  return (
    <div className="container pt-8 sm:pt-24 pb-4 overflow-hidden relative z-10">
      <div className="flex justify-between content-end flex-wrap mb-[35px]">
        <p
          className={cn(
            `text-2xl md:text-[50px]/[60px] max-w-[700px] text-${titleColor}`,
          )}
          style={{ position: "relative", zIndex: "1" }}
        >
          <CustomSourceFlowText path={path} title={title} locale={locale} />
          <span className="text-green">.</span>
        </p>
        <div className="flex content-end flex-wrap mb-[10px]">
          <div className="text-white group w-max">
            <Link
              className={`text-[#1B0D34]  self-end text-[16px] lg:text-xl text-center pt-12 group text-decoration-none group-hover:${titleColor} font-medium text-${titleColor} `}
              href={localizePath("/jobs", locale)}
            >
              <CustomSourceFlowText
                path={`dynamic1.find_role.search`}
                title={locale === "de" ? "Arbeit suchen" : "Search all jobs"}
                locale={locale}
              />
              <span
                className={`w-0 bg-${titleColor} h-[1px] mt-[-10px] transition-all block group-hover:w-full`}
              />
            </Link>
          </div>
        </div>
      </div>
      <ShuffleJobs
        hoverColor={hoverColor}
        titleColor={titleColor}
        jobs={jobs}
      />
    </div>
  );
}
